import "./js/utils/mainOverrides"
import "ant-design-vue/dist/antd.css"
import "./styles/tailwind.css"
import "./styles/main.scss"
import "flag-icons/css/flag-icons.min.css"

import { getFunctions, httpsCallable } from "@firebase/functions"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fal } from "@fortawesome/pro-light-svg-icons"
import { far } from "@fortawesome/pro-regular-svg-icons"
import { fas } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import Antd from "ant-design-vue"
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { collection, collectionGroup, doc, getFirestore, onSnapshot, query, setDoc, where } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage"
import Pusher from "pusher-js"
import Quasar from 'quasar/src/vue-plugin.js';
import {
  AuthenticatedHttp,
  FinancesService,
  FirebaseTokenLoader,
  JobsService,
  LocationsService,
  MessagingService,
  PusherWebSocket,
  SDKObject,
  SDKObjectListener
} from "telarya-sdk"
import { createApp, reactive } from "vue"
import VueGoogleMaps from "vue-google-maps-community-fork"

import { i18n } from "@/i18n"
import Updater from "@/models/Updater"

import App from "./App.vue"
import generateEndpoint from "./endpoint.js"
import { hasPermission } from "./js/Rules"
import storage from "./js/storage"
import Company from "./models/Company"
import User from "./models/User"
import quasarUserOptions from "./quasar-user-options"
import router from "./router"
import CompaniesService from "./services/CompaniesService.js"

function loadTheme(themeName) {
  const head = document.getElementsByTagName("head")[0]
  const link = document.createElement("link")
  link.id = "theme-css"
  link.rel = "stylesheet"
  link.type = "text/css"
  link.href = `${process.env.BASE_URL}css/${themeName}.css`
  link.media = "all"
  head.appendChild(link)
  localStorage.setItem("theme", themeName)
}

// Initial theme load
loadTheme(localStorage.getItem("theme") ?? "telarya-default-light")

// Function to switch themes
function switchTheme(themeName) {
  document.getElementById("theme-css").href = `${process.env.BASE_URL}css/${themeName}.css`
  localStorage.setItem("theme", themeName)
}
library.add(fas, fal, far)
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const firebaseFunctions = getFunctions()
const database = getFirestore()
const fileStorage = getStorage(firebaseApp)
// const firebaseanalytics = getAnalytics(firebase);
let vueApp
let previousCompanyUnsubscribe = null
let app
let myUser
let routeResolver
const customers = reactive([])
const tenant = reactive({})
let googleMapsLoaderResolver = null
const googleMapsLoader = new Promise((resolve) => {
  googleMapsLoaderResolver = resolve
})

if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1")
  window.onerror = function (event, souce, lineno, colon, error) {
    setDoc(doc(collection(database, "errors")), {
      created_at: new Date(),
      user: myUser?.id ?? null,
      event,
      source: souce,
      lineNumber: lineno,
      colonNumber: colon,
      stack: error?.stack ?? null,
      message: error?.message ?? null,
      type: "error"
    })
  }

getAuth().onAuthStateChanged(async (user) => {
  myUser = null
  storage.dispatch("fetchUser", user)
  // user.getIdToken().then((res) => {
  //   console.log("TOKEN", res)
  // })

  if (previousCompanyUnsubscribe != null) {
    previousCompanyUnsubscribe()
  }
  customers.splice(0, customers.length)
  router.user = new Promise((resolve) => {
    routeResolver = resolve
  })
  Object.keys(tenant).forEach((key) => delete tenant[key])
  if (user) {
    previousCompanyUnsubscribe = onSnapshot(
      query(collectionGroup(database, "employees"), where("id", "==", user.uid)),
      (liveSnapshot) => {
        liveSnapshot.docChanges().forEach(async (change) => {
          if (!customers.some((c) => c.id === change.doc.ref.parent.parent.id)) {
            const companyObj = new Company(database, change.doc.ref.parent.parent.id)
            myUser = new User(database, user.uid, change.doc.ref.parent.parent.id)
            await myUser.loadingData
            routeResolver(myUser)
            customers.push(companyObj)
            if (tenant.id == null) {
              Updater.update(tenant, { id: change.doc.ref.parent.parent.id, company: companyObj })
            }
            app.update()
          } else if (change.type === "removed") console.log("TODO DELETE")
        })
      },
      (error) => {
        console.error(error)
      }
    )
  }

  if (vueApp) return

  const { default: firestoreFunctions } = await import("@/firestore")
  if (process.env.VUE_APP_DEVELOPMENT_MODE?.toLowerCase() === "development") Pusher.logToConsole = true
  const webSocket = new PusherWebSocket(
    new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: "eu"
    })
  )

  // Only start the app on firebase check done;
  vueApp = createApp(App).use(Quasar, quasarUserOptions)
  vueApp.provide("switchTheme", switchTheme)
  vueApp.mixin({
    methods: {
      isAuthorized: (key, ...args) => hasPermission(myUser, key, ...args),
      uploadFile: async function (filePath, rawFile) {
        const storageRef = ref(fileStorage, filePath)
        // Upload the file and metadata
        await uploadBytes(storageRef, rawFile)
        return await getDownloadURL(storageRef)
      },
      firedata: firestoreFunctions.firedata,
      /**
       *
       * @param {String} functionName
       * @param {Object} data
       */
      firehttp: function (functionName, data) {
        return httpsCallable(
          firebaseFunctions,
          functionName
        )({
          ...{ language: i18n.global.locale },
          ...data
        })
      },
      log: console.log,
      $tf: (key, fallbackKey, ...params) => {
        return i18n.global.te(key) ? i18n.global.t(key, params) : i18n.global.t(fallbackKey, params)
      }
    }
  })
  vueApp.provide("firebase", firebaseApp)
  // vueApp.provider("firebaseanalytics", firebaseanalytics);
  vueApp.use(router)
  vueApp.component("font-awesome-icon", FontAwesomeIcon)
  vueApp.provide("router", router)
  vueApp.provide("database", database)

  const httpClient = new AuthenticatedHttp(new FirebaseTokenLoader(getAuth()))
  const JobsServiceClient = new JobsService(generateEndpoint("jobs"), httpClient)
  vueApp.provide("JobsServiceClient", JobsServiceClient)
  const LocationsServiceClient = new LocationsService(generateEndpoint("locations"), httpClient)
  vueApp.provide("LocationsServiceClient", LocationsServiceClient)
  vueApp.provide("FinancesServiceClient", new FinancesService(generateEndpoint("finances"), httpClient))
  vueApp.provide("MessagingServiceClient", new MessagingService(generateEndpoint("messaging"), httpClient))

  vueApp.provide("customers", customers)
  vueApp.provide("webSocket", webSocket)
  vueApp.provide("tenant", tenant)
  vueApp.provide("storage", storage)
  vueApp.provide("fileStorage", fileStorage)
  vueApp.provide("googleMapsLoader", googleMapsLoader)
  vueApp.provide("companiesService", new CompaniesService(firebaseFunctions))
  vueApp.use(storage)
  vueApp.use(fileStorage)
  vueApp.use(Antd)
  vueApp.use(i18n)
  vueApp.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
      libraries: "places"
      // language: 'de',
    }
  })
  // vueApp.use(VueGoogleMaps, {
  //   load: {
  //       key: ,
  //   },
  // })
  // GMKEY =
  app = vueApp.mount("#app")

  SDKObjectListener.WebSocket = webSocket
  SDKObjectListener.LocationsServiceClient = LocationsServiceClient
  SDKObjectListener.JobsServiceClient = JobsServiceClient
  // SDKObjectListener.Logging = (...msg) => console.log("SDKObjectListener", msg)
  // SDKObject.ConstructorInterceptor = (item) => {
  //   console.log("ConstructorInterceptor", item)
  //   const { proxy, addPropertyChangedCallback } = ObservableProxy(item)
  //   // Object.assign(item, proxy)
  //   addPropertyChangedCallback((propertyName, newValue) => {
  //     console.log(`Property ${propertyName} changed to ${newValue}`, proxy)
  //   })
  //   return proxy
  // return reactive(item)
  // }
  SDKObject.ProxyCreator = (self) => {
    const proxy = reactive(self)
    self.proxyValueUpdater = (key, value) => {
      proxy[key] = value
    }
    return proxy
  }
  SDKObject.SetPropertyInterceptor = (self, key, value) => {
    if (self.proxyValueUpdater != null) self.proxyValueUpdater(key, value)
  }
  vueApp.$gmapApiPromiseLazy().then((googleMaps) => {
    googleMapsLoaderResolver(googleMaps)
  })
})
