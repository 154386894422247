const generateEndpoint = (service) => {
  let env = ""
  /* eslint-disable no-fallthrough */
  switch (process.env.VUE_APP_DEVELOPMENT_MODE?.toLowerCase()) {
    case "local":
      if (service === "jobs") return "http://localhost:5125"
      if (service === "finances") return "http://localhost:5135"
      if (service === "messaging") return "http://localhost:5159"
      if (service === "locations") return "http://localhost:5018"
      break
    case "development":
      env = "dev"
      if (service === "jobs") return "https://dev.telarya.com:6125"
      if (service === "finances") return "https://dev.telarya.com:6135"
      if (service === "messaging") return "https://dev.telarya.com:6159"
      if (service === "locations") return "https://dev.telarya.com:6018"
      break
    case "test":
      env = "uat"
      break
  }
  /* eslint-enable no-fallthrough */
  return `https://${service}${env.length > 0 ? "." : ""}${env}.api.telarya.com`
}

export default generateEndpoint
