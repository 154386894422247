<template>
  <div
    v-if="devEnvironment"
    class="bg-status-warning fixed z-50 right-12 top-6 mt-9 mr-9 w-80 text-center opacity-30 pointer-events-none"
    style="transform: translateX(50%) rotate(45deg); cursor: inherit">
    <div class="m-1 text-sm">{{ devEnvironment }} environment</div>
  </div>
  <router-view :key="updateCounter" />
</template>

<script>
export default {
  data() {
    return {
      updateCounter: 0,
      devEnvironment: process.env.VUE_APP_DEVELOPMENT_MODE
    }
  },
  methods: {
    update() {
      this.updateCounter += 1
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="scss">
.corners-rounded-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.corners-rounded {
  border-radius: 4px;
}
</style>
